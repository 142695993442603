<template>
  <b-modal id="dialog_customer_remove">
    <template #modal-title>
      <h3><i class="fa fa-trash"></i> ลบผู้จัดจำหน่าย</h3>
    </template>
    <div>
      ต้องการที่จะลบผู้จัดจำหน่ายดังต่อไปนี้ ?
      <ul>
        <template v-for="(data, index) in items">
          <li v-if="data.selected" :key="index">{{data.contact}}</li>
        </template>
      </ul>
    </div>
    <template #modal-footer>
      <b-button variant="danger" @click="removeHandler()"><i class="fa fa-check"></i> ลบเลย</b-button>
      <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
    </template>
  </b-modal>
</template>
<script>
  export default {
    name: 'customer-view-dialog-user-remove',
    data() {
      return {
        show: true
      }
    },
    props: [
      'items', 'successFuction'
    ],
    methods: {
      async removeHandler () {
        const selected = [];
        for(const v of this.items){
          if(v.selected){
            selected.push(v.id);
          }
        }
        if (selected === 0) {
          this.AlertUtils.alert('warning', `กรุณาเลือกข้อมูลที่ต้องการลบ`);
          this.$bvModal.hide('dialog_customer_remove');
          return;
        }
        const result = await this.HttpServices.postData("/ab_customer/delete", {id: selected});
        if(result&&result.status.code=="200"){
          this.AlertUtils.alert('success', `บันทึกสำเร็จ`);
          this.$bvModal.hide('dialog_customer_remove');
          this.$emit("successFuction", "");
        }else{
          this.AlertUtils.alert('warning', result.status.message);
        }
      },
      close () {
        this.$bvModal.hide('dialog_customer_remove');
      }
    }
  }
</script>
