export default {
  customer: {
    rows: [{
      id: 1,
      name: 'บริษัท ดอกไม้',
      contact: 'นายเก่ง',
      phone: '081-633-0101',
      line: '@flower',
      email: 'flower@gmail.com',
      billing_address: '',
      tax_id: '',
      address: '',
      created: '',
      updated: '',
      remark: '-',
      credit_status: 0,
      credit_line: '2000',
      order: {
        rows:[{
          id: 1,
          created: '',
          updated: '',
          date_order: '02/04/2021',
          status_order: 0,
          status_payment: 1,
          status_delivery: 1,
          remark: '',
          total: '1450.00',
          cost_product: '2500.00',
          cost_shipping: '2500.00',
          cost_discount: '10',
          cost_extra: '200',
          price_product: '2500.00',
          price_shipping: '2500.00',
          price_extra: '2500.00',
          user_id: 1,
          sales_id: 1,
          delivery_phone: '0909090909',
          delivery_contact: 'นายทองแดง',
          delivery_address: '12121/2323 ชั้น 2',
          delivery_location: 'พระโขนง',
          store_id: 1,
          status_refund: 0,
          price_refund: '',
          channel_id: 1,
          issue_type: 1
        }, {
          id: 2,
          created: '',
          updated: '',
          date_order: '02/04/2021',
          status_order: 1,
          status_payment: 1,
          status_delivery: 3,
          remark: '',
          total: '1450.00',
          cost_product: '2500.00',
          cost_shipping: '2500.00',
          cost_discount: '10',
          cost_extra: '200',
          price_product: '2500.00',
          price_shipping: '2500.00',
          price_extra: '2500.00',
          user_id: 1,
          sales_id: 1,
          delivery_phone: '0909090909',
          delivery_contact: 'นายทอง',
          delivery_address: '12121/2323 ชั้น 2',
          delivery_location: 'พระโขนง',
          store_id: 1,
          status_refund: 0,
          price_refund: '',
          channel_id: 2,
          issue_type: 3
        }, {
          id: 3,
          created: '',
          updated: '',
          date_order: '02/04/2021',
          status_order: 2,
          status_payment: 2,
          status_delivery: 4,
          remark: '',
          total: '1450.00',
          cost_product: '2500.00',
          cost_shipping: '2500.00',
          cost_discount: '10',
          cost_extra: '200',
          price_product: '2500.00',
          price_shipping: '2500.00',
          price_extra: '2500.00',
          user_id: 1,
          sales_id: 1,
          delivery_phone: '0909090909',
          delivery_contact: 'นายทองแดง',
          delivery_address: '12121/2323 ชั้น 2',
          delivery_location: 'พระโขนง',
          store_id: 1,
          status_refund: 0,
          price_refund: '',
          channel_id: 3,
          issue_type: 4
        }, {
          id: 4,
          created: '',
          updated: '',
          date_order: '02/04/2021',
          status_order: 0,
          status_payment: 1,
          status_delivery: 1,
          remark: '',
          total: '1450.00',
          cost_product: '2500.00',
          cost_shipping: '2500.00',
          cost_discount: '10',
          cost_extra: '200',
          price_product: '2500.00',
          price_shipping: '2500.00',
          price_extra: '2500.00',
          user_id: 1,
          sales_id: 1,
          delivery_phone: '0909090909',
          delivery_contact: 'นายทองแดง',
          delivery_address: '12121/2323 ชั้น 2',
          delivery_location: 'พระโขนง',
          store_id: 1,
          status_refund: 0,
          price_refund: '',
          channel_id: '',
          issue_type: 2
        }, {
          id: 5,
          created: '',
          updated: '',
          date_order: '02/04/2021',
          status_order: 0,
          status_payment: 1,
          status_delivery: 2,
          remark: '',
          total: '1450.00',
          cost_product: '2500.00',
          cost_shipping: '2500.00',
          cost_discount: '10',
          cost_extra: '200',
          price_product: '2500.00',
          price_shipping: '2500.00',
          price_extra: '2500.00',
          user_id: 1,
          sales_id: 1,
          delivery_phone: '0909090909',
          delivery_contact: 'นายทองแดง',
          delivery_address: '12121/2323 ชั้น 2',
          delivery_location: 'พระโขนง',
          store_id: 1,
          status_refund: 0,
          price_refund: '',
          channel_id: '',
          issue_type: 1
        }],
        paging: {
          rowTotal: 1
        }
      }
    }, {
      id: 2,
      name: 'บ้านสวน',
      contact: 'ทองแดง',
      phone: '081-010-0101',
      line: '@flower34',
      email: 'fxdd@gmail.com',
      billing_address: '',
      tax_id: '',
      address: '',
      created: '',
      updated: '',
      remark: 'อยู่หลังดอนเมือง',
      credit_status: 1,
      credit_line: '1500',
      order: {
        rows:[{
          id: 1,
          created: '',
          updated: '',
          date_order: '02/04/2021',
          status_order: 0,
          status_payment: 1,
          status_delivery: 1,
          remark: '',
          total: '1450.00',
          cost_product: '2500.00',
          cost_shipping: '2500.00',
          cost_discount: '10',
          cost_extra: '200',
          price_product: '2500.00',
          price_shipping: '2500.00',
          price_extra: '2500.00',
          user_id: 1,
          sales_id: 1,
          delivery_phone: '0909090909',
          delivery_contact: 'นายทองแดง',
          delivery_address: '12121/2323 ชั้น 2',
          delivery_location: 'พระโขนง',
          store_id: 1,
          status_refund: 0,
          price_refund: '',
          channel_id: 1,
          issue_type: 1
        }, {
          id: 2,
          created: '',
          updated: '',
          date_order: '02/04/2021',
          status_order: 1,
          status_payment: 1,
          status_delivery: 3,
          remark: '',
          total: '1450.00',
          cost_product: '2500.00',
          cost_shipping: '2500.00',
          cost_discount: '10',
          cost_extra: '200',
          price_product: '2500.00',
          price_shipping: '2500.00',
          price_extra: '2500.00',
          user_id: 1,
          sales_id: 1,
          delivery_phone: '0909090909',
          delivery_contact: 'นายทอง',
          delivery_address: '12121/2323 ชั้น 2',
          delivery_location: 'พระโขนง',
          store_id: 1,
          status_refund: 0,
          price_refund: '',
          channel_id: 2,
          issue_type: 3
        }, {
          id: 3,
          created: '',
          updated: '',
          date_order: '02/04/2021',
          status_order: 2,
          status_payment: 2,
          status_delivery: 4,
          remark: '',
          total: '1450.00',
          cost_product: '2500.00',
          cost_shipping: '2500.00',
          cost_discount: '10',
          cost_extra: '200',
          price_product: '2500.00',
          price_shipping: '2500.00',
          price_extra: '2500.00',
          user_id: 1,
          sales_id: 1,
          delivery_phone: '0909090909',
          delivery_contact: 'นายทองแดง',
          delivery_address: '12121/2323 ชั้น 2',
          delivery_location: 'พระโขนง',
          store_id: 1,
          status_refund: 0,
          price_refund: '',
          channel_id: 3,
          issue_type: 4
        }, {
          id: 4,
          created: '',
          updated: '',
          date_order: '02/04/2021',
          status_order: 0,
          status_payment: 1,
          status_delivery: 1,
          remark: '',
          total: '1450.00',
          cost_product: '2500.00',
          cost_shipping: '2500.00',
          cost_discount: '10',
          cost_extra: '200',
          price_product: '2500.00',
          price_shipping: '2500.00',
          price_extra: '2500.00',
          user_id: 1,
          sales_id: 1,
          delivery_phone: '0909090909',
          delivery_contact: 'นายทองแดง',
          delivery_address: '12121/2323 ชั้น 2',
          delivery_location: 'พระโขนง',
          store_id: 1,
          status_refund: 0,
          price_refund: '',
          channel_id: '',
          issue_type: 2
        }, {
          id: 5,
          created: '',
          updated: '',
          date_order: '02/04/2021',
          status_order: 0,
          status_payment: 1,
          status_delivery: 2,
          remark: '',
          total: '1450.00',
          cost_product: '2500.00',
          cost_shipping: '2500.00',
          cost_discount: '10',
          cost_extra: '200',
          price_product: '2500.00',
          price_shipping: '2500.00',
          price_extra: '2500.00',
          user_id: 1,
          sales_id: 1,
          delivery_phone: '0909090909',
          delivery_contact: 'นายทองแดง',
          delivery_address: '12121/2323 ชั้น 2',
          delivery_location: 'พระโขนง',
          store_id: 1,
          status_refund: 0,
          price_refund: '',
          channel_id: '',
          issue_type: 1
        }],
        paging: {
          rowTotal: 1
        }
      }
    }],
    paging: {
      page: 1,
      rowTotal: 2
    }
  },
  search_filters: {
    name_comp_filters: [{
      id: 1,
      name: 'บริษัท ดอกไม้',
    }, {
      id: 2,
      name: 'บ้านสวน',
    }],
    name_contr_filters: [{
      id: 1,
      name: 'นายเก่ง'
    }, {
      id: 2,
      name: 'ทองแดง'
    }]
  },
  types: [{
    id: 1,
    name: 'นิติบุคคล'
  }, {
    id: 2,
    name: 'บุคคลธรรมดา'
  }],
  status_order: [{
    id: 0,
    name: 'เปิดการขาย'
  }, {
    id: 1,
    name: 'ยืนยันการขาย'
  }, {
    id: 2,
    name: 'Approved'
  }, {
    id: 3,
    name: 'ส่งรายการให้ร้านค้า'
  }, {
    id: 4,
    name: 'ปิดการขายเรียบร้อย'
  }, {
    id: 5,
    name: 'พบปัญหารอการแก้ไข'
  }],
  status_payment: [{
    id: 1,
    name: 'รอชำระเงิน'
  }, {
    id: 2,
    name: 'ตรวจสอบการชำระเงินแล้ว'
  }],
  status_refund: [{
    id: 0,
    name: 'ปกติไม่มีการคืนเงิน'
  }, {
    id: 1,
    name: 'คืนเงินบางส่วน'
  }, {
    id: 2,
    name: 'คืนเงินเต็มจำนวน'
  }],
  status_delivery: [{
    id: 1,
    name: 'รอรููปจัดเสร็จ'
  }, {
    id: 2,
    name: 'ส่งรูปจัดเสร็จแล้ว'
  }, {
    id: 3,
    name: 'รอรูปผู้รับ'
  }, {
    id: 4,
    name: 'ส่งรูปผู้รับแล้ว'
  }]
}
